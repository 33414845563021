<template>
  <div>
    <UserForm :user-data="transformedUserData" :mode="'profile'" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserForm from './UserForm.vue'

export default {
  components: {
    UserForm,
  },
  computed: {
    ...mapGetters({
      currentUser: 'users/currentUser',
    }),
    // Structure expected by UserForm
    transformedUserData() {
      const currentUserObj = {
        id: this.currentUser.id,
        nom: this.currentUser.lastName.toUpperCase(),
        prenom: this.currentUser.firstName,
        identifiant: this.currentUser.userName,
        departement: this.currentUser.profile.department,
        email: this.currentUser.email,
        telephone: this.currentUser.phoneNumber,
        roles: this.currentUser.roles,
        status: this.currentUser.profile.isActive,
        isBackup: this.currentUser.profile.isBackup,
        callRecording: this.currentUser.profile.callRecording,
      }
      return currentUserObj
    },
  },
}
</script>
